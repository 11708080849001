/* Custom page CSS
-------------------------------------------------- */

/* Not required for template or sticky footer method. */

@font-face {
  font-family: 'digital7-monoitalic';
  src: url('./static/fonts/digital-7.monoitalic.ttf');
}

.bg-dark {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  background-color: #6eade3!important;
  
}

main>.container {
  padding: 30px 15px 0;
}

.footer {
  background-color: #f5f5f5;
}

.footer>.container {
  padding-right: 15px;
  padding-left: 15px;
  /* text-align: center; */
}

code {
  font-size: 80%;
}

.mt-main {
  padding-top: 75px;
}

.logo {
  margin-right: 5px;
  vertical-align: top;
}

footer .list-inline>li>a::after {
  content: "|";
  padding-left: 10px;
}

footer .list-inline>li:last-child>a::after {
  content: none;
}

pre {
  overflow: visible;
}

.lang-list .list-inline>li>a::after {
  content: "|";
  padding-left: 10px;
}

.lang-list .list-inline>li:last-child>a::after {
  content: none;
}

.badge {
  padding: .8em;
  margin: .2em;
}

.counts-info {
  font-size: small;
}

.timer-main .google-auto-placed {
  display: none;
}

.timer {
  font-size: 145px;
  font-family: digital7-monoitalic, monospace;
  color: #6eade3;
  background-color: black;
}

.timer input[type="number"] {
  width: 140px;
  background-color: inherit;
  color: inherit;
  border: none;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timer input[type="number"]:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.timer-ms {
  width: 265px !important;
}

.btn-timer {
  padding: 10px 50px 10px 50px;
  font-size: 30px;
}

.btn-timer span {
  display: block;
  font-size: 15px;
  font-style: italic;
}

.btn-shortcut {
  font-size: 25px;
  width: 115px
}

.calendar-display {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 25px;
  display: block;
  text-align: center;
  padding-bottom: 15px;
}

.city-display {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 25px;
  display: block;
  text-align: center;
  padding-bottom: 5px;
}


.search-box {
  width: 400px; 
  display: inline-block ;
}

.dateTime {
  text-align: center;
  font-size: xx-large;
  width: 45%;
  margin-right: 10px;
}

.dateTime-container {
  place-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.btn-wrap {
  white-space: normal !important;
}

.container.no-ad>.google-auto-placed {
  display: none !important;
}

.all-no-ad ins {
  display: none !important;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.btn-clipboard {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 10;
  display: block;
  padding: .25rem .5rem;
  font-size: 75%;
  color: #818a91;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: .25rem;
}

.btn-clipboard:hover {
  color: #fff;
  background-color: #027de7;
}

.text-result {
  background-color: #f7f7f9;
  height: 293px;
  overflow: auto;
  width: 100%;
  margin-bottom: 7px;
}

.text-result pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.timer-pomodoro {
  font-size: 65px;
  font-family: monospace;
  color: #fff;
  /* background-image: url('/img/pomodoro.png'); */
  background-repeat: no-repeat;
  background-size: 455px;
  background-position: center;
  height: 435px;
}

.timer-pomodoro .display {
  margin-top: 75px;
}

.bomb {
    width: 650px;
    position: absolute;
    left: 0;
    right: 0;
    top: -175px;
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
}

.timer-bomb {
  font-size: 50px;
  font-family: monospace;
  padding-top: 200px;
  padding-bottom: 170px;
  color: #fff;
  position: relative;
}

.timer-bomb input[type="number"] {
  width: 65px;
  background-color: inherit;
  color: inherit;
  border: none;
  -moz-appearance: textfield;
}

.timer-bomb input[type="number"]:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.timer-bomb-ms {
  width: 140px !important;
}

.rocket {
  width: 830px;
  position: absolute;
  left: 0;
  right: 0;
  top: -105px;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
}
.timer-rocket {
  font-size: 50px;
  font-family: monospace;
  padding-top: 335px;
  padding-bottom: 15px;
  color: #00ff09;
  position: relative;
}
/* .timer-display {
  background-image: url('/img/platform.png');
} */
.timer-rocket input[type="number"] {
  width: 65px;
  background-color: inherit;
  color: #00ff09;
  border: none;
  -moz-appearance: textfield;
}
.timer-rocket input[type="number"]:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.timer-rocket-ms {
  width: 100px !important;
}

.clock {
  font-size: 145px;
  font-family: digital7-monoitalic;
  color: #6eade3;
  background-color: #33333d;
  position: absolute;
  width: 1140px !important;
  border: 0 solid #d2d6dc;
  padding-top: 15px;
  border-radius: 0.375rem;

}
.clock .amPm {
  font-size: 65%;
  margin-left: 4%;
}
.clock-logo {
  font-size: 15px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  padding-bottom: 5px;
  color: #fff !important;
  position: absolute;
  bottom: 0px;
  right: 0px;
  text-decoration: none !important;
}
.clock-logo img {
  width: 20px;
  margin: 0;
  margin-right: 3px;
}
.clock-close-fs {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 15px;
  padding-right: 15px;
  display: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
  margin: 0;
}
.clock-close-fs:focus {
  outline: 0;
}
.clock-display {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-bottom: 10px;
}
.clock-config{
  margin-top: 210px !important;
}
.clock-config .form-check-inline{
  display: block !important;
  padding: 0.3em;
}

/* Landscape tablets and medium desktops */

@media (min-width: 992px) and (max-width: 1199px) {
  .timer {
    font-size: 110px;
  }
  .timer input[type="number"] {
    width: 140px;
  }
  .timer-ms {
    width: 205px !important;
  }
  .card-uuid {
    min-width: 285px;
  }
  .clock {
    font-size: 110px;
    width: 700px !important;
  }
  .clock-logo img {
    width: 13px;
  }
  .clock-close-fs {
    padding: 0;
    margin-right: 5px;
    width: 18px;
  }  
  .clock-logo {
    font-size: 9px;
    padding-bottom: 2px;
    margin-right: 5px;
  }
  .clock-config{
    margin-top: 175px !important;
  }
  .city-display {
    font-size: 9px;
  }
  .calendar-display {
    font-size: 16px;
  }
  .search-box {
    display: inline-block;  
  }
}

/* Portrait tablets and small desktops */

@media (min-width: 768px) and (max-width: 991px) {
  .timer {
    font-size: 90px;
  }
  .timer input[type="number"] {
    width: 115px;
  }
  .timer-ms {
    width: 165px !important;
  }
  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 25px;
  }
  .btn-timer span {
    font-size: 19px;
  }
  .dateTime {
    font-size: large;
  }
  .card-uuid {
    min-width: 205px;
  }
  .rocket {
    width: 605px;
    left: 0;
    right: 0;
    top: -60px;
  }
  .timer-rocket {
    font-size: 40px;
    padding-top: 270px;
    padding-bottom: 10px;
  }
  .timer-rocket input[type="number"] {
    width: 53px;
  }
  .timer-rocket-ms {
    width: 75px !important;
  }
  .clock {
    font-size: 80px;
    width: 532px !important;
  }
  .clock-logo img {
    width: 13px;
  }
  .clock-close-fs {
    padding: 0;
    margin-right: 5px;
    width: 18px;
  }  
  .clock-logo {
    font-size: 9px;
    padding-bottom: 2px;
    margin-right: 5px;
  }
  .clock-config{
    margin-top: 140px !important;
  }
  .city-display {
    font-size: 16px;
  }
  .calendar-display {
    font-size: 16px;
  }
  .search-box {
    display: inline-block;  
  }
}

/* Landscape phones and portrait tablets */

@media (min-width: 575.99px) and (max-width: 767px) {
  .timer {
    font-size: 45px;
  }
  .timer input[type="number"] {
    width: 60px;
  }
  .timer-ms {
    width: 85px !important;
  }
  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 15px;
  }
  .btn-timer span {
    font-size: 9px;
  }
  .dateTime {
    font-size: larger;
    width: 100%;
    margin-bottom: 15px;
  }
  .dateTime-container {
    flex-direction: column;
  }
  .card-uuid {
    min-width: 145px;
  }
  .timer-pomodoro {
    font-size: 50px;
    background-size: 330px;
    height: 320px;
  }
  .bomb {
    width: 355px;
    top: -114px;
    left: inherit;
    right: inherit;
  }
  .timer-bomb {
    font-size: 26px;
    padding-top: 92px;
    padding-bottom: 85px;
  }
  .timer-bomb input[type="number"] {
    width: 35px;
  }
  .timer-bomb-ms {
    width: 80px !important;
  }
  .rocket {
    width: 365px;
    left: 0;
    right: 0;
    top: -25px;
  }
  .timer-rocket {
    font-size: 22px;
    padding-top: 175px;
    padding-bottom: 10px;
  }
  .timer-rocket input[type="number"] {
    width: 35px;
  }
  .timer-rocket-ms {
    width: 45px !important;
  }
  .clock {
    font-size: 80px;
    width: 532px !important;
  }
  .clock-logo img {
    width: 13px;
  }
  .clock-close-fs {
    padding: 0;
    margin-right: 5px;
    width: 18px;
  }  
  .clock-logo {
    font-size: 9px;
    padding-bottom: 2px;
    margin-right: 5px;
  }
  .clock-config{
    margin-top: 140px !important;
  }
  .city-display {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .calendar-display {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .search-box {
    display: inline-block; 
  }
}

/* Portrait phones and smaller */

@media (min-width: 280.99px) and (max-width: 575.98px) {
  .timer {
    font-size: 40px;
  }
  .timer input[type="number"] {
    width: 50px;
  }
  .timer-ms {
    width: 75px !important;
  }
  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 15px;
  }
  .btn-timer span {
    font-size: 9px;
  }
  .dateTime {
    font-size: small;
    width: 100%;
    margin-bottom: 15px;
  }
  .dateTime-container {
    flex-direction: column;
  }
  .timer-pomodoro {
    font-size: 45px;
    background-size: 295px;
    height: 285px;
  }
  .timer-pomodoro+div>div>label {
    font-size: small;
  }
  .bomb {
    width: 315px;
    top: -100px;
    left: inherit;
    right: inherit;
  }
  .timer-bomb {
    font-size: 22px;
    padding-top: 84px;
    padding-bottom: 85px;
  }
  .timer-bomb input[type="number"] {
    width: 35px;
  }
  .timer-bomb-ms {
    width: 68px !important;
  }
  .rocket {
    width: 315px;
    left: 0;
    right: 0;
    top: -25px;
  }
  .timer-rocket {
    font-size: 22px;
    padding-top: 147px;
    padding-bottom: 10px;
  }
  .timer-rocket input[type="number"] {
    width: 35px;
  }
  .timer-rocket-ms {
    width: 45px !important;
  }
  .clock {
    font-size: 40px;
    width: 280px !important;
    padding-top: 5px;
    /* padding-bottom: 6px; */
  }
  .clock-logo img {
    width: 9px;
  }
  .clock-close-fs {
    padding: 0;
    margin-right: 5px;
    width: 18px;
  }  
  .clock-logo {
    font-size: 6px;
    padding-bottom: 2px;
    margin-right: 5px;
  }
  .clock-config{
    margin-top: 90px !important;
  }
  .city-display {
    font-size: 9px;
    padding-bottom: 5px;
  }
  .calendar-display {
    font-size: 9px;
    padding-bottom: 10px;
  }
  .search-box {
    width: 300px;
    display: inline-block; 
  }
  .badge{
    white-space: inherit;
  }
}

/* To embed very smaller */

@media (max-width: 280.98px) {
  .clock {
    font-size: 13vw;
    width: 93vw !important;
    height: 23vw;
    padding-top: 0;
  }
  .clock-logo img {
    width: 3vw;
  }
  .clock-close-fs {
    padding: 0;
    margin-right: 5px;
    width: 18px;
  }  
  .clock-logo {
    font-size: 2vw;
    padding-bottom: 0;
    margin-right: 2vw;

  }
  .clock-config{
    margin-top: 90px !important;
  }
  .clock-display{
    margin: 0;
  }
  .city-display {
    font-size: 3vw;
    padding-bottom: 0;
  }
  .calendar-display {
    font-size: 5vw;
    padding-bottom: 0;
  }
  .search-box {
    display: none;
  }

}